import React, { useState } from "react";
import {
  FormColumn,
  Img,
  ImgWrapper,
  FormWrapper,
  FormInput,
  FormSection,
  FormRow,
  FormTitle,
  FormLabel,
  FormInputRow,
  FormMessage,
  NavItemBtn,
  NavBtnLink,
  ButtonContainer,
} from "./FormStyles";
import { Container, Button, TextWrapper, Column } from "../../globalStyles";
import { orange, white } from "../../Colors";
import validateLoginForm from "./validateLloginForm";
import ImageGallery from "react-image-gallery";

const Newyear = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const submitHandler = (e) => {
    e.preventDefault();
    console.log({ name });
    const resultError = validateLoginForm({ email, password });

    if (resultError !== null) {
      setError(resultError);
      return;
    }

    console.log(e);
    setName("");
    setPassword("");
    setError(null);
    setSuccess("Application was submitted!");
  };

  const messageVariants = {
    hidden: { y: 30, opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { delay: 0.2, duration: 0.4 } },
  };

  const buttonVariants = {
    hover: {
      scale: 1.05,
      color: white,
      boxShadow: "0px 0px 8px white",
      transition: {
        duration: 0.3,
        yoyo: Infinity,
      },
    },
  };

  const formData = [
    {
      label: "UserName",
      value: name,
      onChange: (e) => setName(e.target.value),
      type: "text",
    },
    {
      label: "Password",
      value: password,
      onChange: (e) => setPassword(e.target.value),
      type: "password",
    },
  ];

  const party1 = "https://slide-lokalai.s3.amazonaws.com/Slide1.JPG";

  return (
    <FormSection>
      <Container>
        <FormRow>
          <FormColumn>
            <FormTitle>CUNG GIAO THUA</FormTitle>
            <Column align="center" mt="10%" width="100%">
              <ButtonContainer
                style={{
                  border: "1px dotted white",
                  padding: "10px",
                  maxWidth: "350px",
                }}
              >
                <Button fontBig primary>
                  <a
                    href={"cung ong ba 2024.pdf"}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "WHITE" }}
                  >
                    Cúng ông Bà 2024
                  </a>
                </Button>
                <Button fontBig primary>
                  <a
                    href={"cung giao thua 2025.pdf"}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "WHITE" }}
                  >
                    Cúng Giao Thừa 2025
                  </a>
                </Button>
              </ButtonContainer>
            </Column>
          </FormColumn>
        </FormRow>
      </Container>
    </FormSection>
  );
};

export default Newyear;
