import React, { useState } from "react";
import {
  FormColumn,
  Img,
  ImgWrapper,
  FormWrapper,
  FormInput,
  FormSection,
  FormRow,
  FormTitle,
  FormLabel,
  FormInputRow,
  FormMessage,
  NavItemBtn,
  NavBtnLink,
  ButtonContainer,
} from "./FormStyles";
import { Container, Button, TextWrapper, Column } from "../../globalStyles";
import { orange, white } from "../../Colors";
import validateLoginForm from "./validateLloginForm";
import ImageGallery from "react-image-gallery";

const Business = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const submitHandler = (e) => {
    e.preventDefault();
	console.log({name})
    const resultError = validateLoginForm({ email, password });

    if (resultError !== null) {
      setError(resultError);
      return;
    }

    console.log(e);
	setName("");
    setPassword("");
    setError(null);
    setSuccess("Application was submitted!");
  };


  const messageVariants = {
    hidden: { y: 30, opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { delay: 0.2, duration: 0.4 } },
  };

  const buttonVariants = {
    hover: {
      scale: 1.05,
      color: white,
      boxShadow: "0px 0px 8px white",
      transition: {
        duration: 0.3,
        yoyo: Infinity,
      },
    },
  };

  const formData = [
    {
      label: "UserName",
      value: name,
      onChange: (e) => setName(e.target.value),
      type: "text",
    },
    {
      label: "Password",
      value: password,
      onChange: (e) => setPassword(e.target.value),
      type: "password",
    },
  ];

  const party1 = "https://slide-lokalai.s3.amazonaws.com/Slide1.JPG";
  const images = [
    {
      original: "https://slide-lokalai.s3.amazonaws.com/Slide1.JPG",
      thumbnail: "https://slide-lokalai.s3.amazonaws.com/Slide1.JPG",
    },
    {
      original: "https://slide-lokalai.s3.amazonaws.com/Slide2.JPG",
      thumbnail: "https://slide-lokalai.s3.amazonaws.com/Slide2.JPG",
    },
    {
      original: "https://slide-lokalai.s3.amazonaws.com/Slide3.JPG",
      thumbnail: "https://slide-lokalai.s3.amazonaws.com/Slide3.JPG",
    },
    {
      original: "https://slide-lokalai.s3.amazonaws.com/Slide4.JPG",
      thumbnail: "https://slide-lokalai.s3.amazonaws.com/Slide4.JPG",
    },
    {
      original: "https://slide-lokalai.s3.amazonaws.com/Slide5.JPG",
      thumbnail: "https://slide-lokalai.s3.amazonaws.com/Slide5.JPG",
    },
    {
      original: "https://slide-lokalai.s3.amazonaws.com/Slide6.JPG",
      thumbnail: "https://slide-lokalai.s3.amazonaws.com/Slide6.JPG",
    },
    {
      original: "https://slide-lokalai.s3.amazonaws.com/Slide7.JPG",
      thumbnail: "https://slide-lokalai.s3.amazonaws.com/Slide7.JPG",
    },
    {
      original: "https://slide-lokalai.s3.amazonaws.com/Slide8.JPG",
      thumbnail: "https://slide-lokalai.s3.amazonaws.com/Slide8.JPG",
    },
    {
      original: "https://slide-lokalai.s3.amazonaws.com/Slide9.JPG",
      thumbnail: "https://slide-lokalai.s3.amazonaws.com/Slide9.JPG",
    },
    {
      original: "https://slide-lokalai.s3.amazonaws.com/Slide10.JPG",
      thumbnail: "https://slide-lokalai.s3.amazonaws.com/Slide10.JPG",
    },
    {
      original: "https://slide-lokalai.s3.amazonaws.com/Slide11.JPG",
      thumbnail: "https://slide-lokalai.s3.amazonaws.com/Slide11.JPG",
    },
    {
      original: "https://slide-lokalai.s3.amazonaws.com/Slide12.JPG",
      thumbnail: "https://slide-lokalai.s3.amazonaws.com/Slide12.JPG",
    },
    {
      original: "https://slide-lokalai.s3.amazonaws.com/Slide13.JPG",
      thumbnail: "https://slide-lokalai.s3.amazonaws.com/Slide13.JPG",
    },
    {
      original: "https://slide-lokalai.s3.amazonaws.com/Slide14.JPG",
      thumbnail: "https://slide-lokalai.s3.amazonaws.com/Slide14.JPG",
    },
    {
      original: "https://slide-lokalai.s3.amazonaws.com/Slide15.JPG",
      thumbnail: "https://slide-lokalai.s3.amazonaws.com/Slide15.JPG",
    },
    {
      original: "https://slide-lokalai.s3.amazonaws.com/Slide16.JPG",
      thumbnail: "https://slide-lokalai.s3.amazonaws.com/Slide16.JPG",
    },
    {
      original: "https://slide-lokalai.s3.amazonaws.com/Slide17.JPG",
      thumbnail: "https://slide-lokalai.s3.amazonaws.com/Slide17.JPG",
    },
    {
      original: "https://slide-lokalai.s3.amazonaws.com/Slide18.JPG",
      thumbnail: "https://slide-lokalai.s3.amazonaws.com/Slide18.JPG",
    },
    {
      original: "https://slide-lokalai.s3.amazonaws.com/Slide19.JPG",
      thumbnail: "https://slide-lokalai.s3.amazonaws.com/Slide19.JPG",
    },
    {
      original: "https://slide-lokalai.s3.amazonaws.com/Slide20.JPG",
      thumbnail: "https://slide-lokalai.s3.amazonaws.com/Slide20.JPG",
    },
    {
      original: "https://slide-lokalai.s3.amazonaws.com/Slide21.JPG",
      thumbnail: "https://slide-lokalai.s3.amazonaws.com/Slide21.JPG",
    },
    {
      original: "https://slide-lokalai.s3.amazonaws.com/Slide22.JPG",
      thumbnail: "https://slide-lokalai.s3.amazonaws.com/Slide22.JPG",
    },
    {
      original: "https://slide-lokalai.s3.amazonaws.com/Slide23.JPG",
      thumbnail: "https://slide-lokalai.s3.amazonaws.com/Slide23.JPG",
    },
    {
      original: "https://slide-lokalai.s3.amazonaws.com/Slide24.JPG",
      thumbnail: "https://slide-lokalai.s3.amazonaws.com/Slide24.JPG",
    },
    {
      original: "https://slide-lokalai.s3.amazonaws.com/Slide25.JPG",
      thumbnail: "https://slide-lokalai.s3.amazonaws.com/Slide25.JPG",
    },
    {
      original: "https://slide-lokalai.s3.amazonaws.com/Slide26.JPG",
      thumbnail: "https://slide-lokalai.s3.amazonaws.com/Slide26.JPG",
    },
    {
      original: "https://slide-lokalai.s3.amazonaws.com/Slide27.JPG",
      thumbnail: "https://slide-lokalai.s3.amazonaws.com/Slide27.JPG",
    },
    {
      original: "https://slide-lokalai.s3.amazonaws.com/Slide28.JPG",
      thumbnail: "https://slide-lokalai.s3.amazonaws.com/Slide28.JPG",
    },
    {
      original: "https://slide-lokalai.s3.amazonaws.com/Slide29.JPG",
      thumbnail: "https://slide-lokalai.s3.amazonaws.com/Slide29.JPG",
    },
  ];

  return (
    <FormSection>
      <Container>
        <FormRow>
          <FormColumn>
            <FormTitle>Lokalai Business Plan</FormTitle>

			{!success && (
            <FormWrapper onSubmit={submitHandler}>
              {formData.map((el, index) => (
                <FormInputRow key={index}>
                  <FormLabel>{el.label}</FormLabel>
                  <FormInput
                    type={el.type}
                    placeholder={`Enter your ${el.label.toLocaleLowerCase()}`}
                    value={el.value}
                    onChange={el.onChange}
                  />
                </FormInputRow>
              ))}

              <Button
                variants={buttonVariants}
                whileHover="hover"
                type="submit"
              >
                Log In
              </Button>
            </FormWrapper>
			)}

            {error && (
              <FormMessage
                variants={messageVariants}
                initial="hidden"
                animate="animate"
                error
              >
                {error}
              </FormMessage>
            )}
            {success && (
              <>
			  	<TextWrapper
                    color="white"
                    size="clamp(1rem,3vw,1.3rem)"
                    lineHeight="1.1rem"
                    maxWidth="480px"
                    align="center"
					margin="0 auto"
					style={{marginBottom: "1rem"}}
                  >
                    This is a short version of Lokalai busines plan. You can download a long version bellow. 
                  </TextWrapper>
                <div style={{ marginBottom: "50px" }}>

                  <ImageGallery
                    defaultImage={party1}
                    items={images}
                    showBullets={false}
                    showThumbnails={false}
                    showNav={true}
                  />
                </div>
                <hr style={{ marginTop: "10px", border: "1px solid red" }} />
                <Column
                  align="center"
                  mt="10%"
                  width="100%"
                  
                >
                  <TextWrapper
                    color="white"
                    size="clamp(1rem,3vw,1.3rem)"
                    margin="0 0 1rem"
                    lineHeight="1.1rem"
                    maxWidth="480px"
                    align="center"
                  >
                    Download busines plan (SHORT version)
                  </TextWrapper>
                  <ButtonContainer style={{ border: "1px dotted white", padding: "10px", maxWidth:"350px" }}>
                    <Button fontBig primary>
                      <a
                        href={"Lokalai_short.pptx"}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "#DC1C13" }}
                      >
                        PPT
                      </a>
                    </Button>

                    <Button fontBig primary>
                      <a
                        href={"Lokalai_short.pdf"}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "WHITE" }}
                      >
                        PDF
                      </a>
                    </Button>

                  </ButtonContainer>
                </Column>
                <Column
                  align="center"
                  mt="5%"
                  width="100%"
                 
                >
                  <TextWrapper
                    color="white"
                    size="clamp(1rem,3vw,1.3rem)"
                    margin="0 0 1rem"
                    lineHeight="1.1rem"
                    maxWidth="480px"
                    align="center"
                  >
                    Download busines plan (FULL version)
                  </TextWrapper>
                  <ButtonContainer  style={{ border: "1px dotted #DC1C13", padding: "10px", maxWidth: "350px" }}>
                    <Button fontBig primary>
                      <a
                        href={"Lokalai.pptx"}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "#DC1C13" }}
                      >
                        PPT
                      </a>
                    </Button>

                    <Button fontBig primary>
                      <a
                        href={"Lokalai_full.pdf"}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "WHITE" }}
                      >
                        PDF
                      </a>
                    </Button>
                  </ButtonContainer>
                </Column>
                <Column
                  align="center"
                  mt="5%"
                  width="100%"
                >
                  <TextWrapper
                    color="white"
                    size="clamp(1rem,3vw,1.3rem)"
                    margin="0 0 1rem"
                    lineHeight="1.1rem"
                    maxWidth="480px"
                    align="center"
                  >
                    Download full detail Business plan (Word file)
                  </TextWrapper>
                  <ButtonContainer style={{ border: "1px dotted white", padding: "10px", maxWidth:"350px" }}>
                    <Button fontBig primary>
                      <a
                        href={"Lokalai_business_plan.pdf"}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "#DC1C13" }}
                      >
                        PDF
                      </a>
                    </Button>
                  </ButtonContainer>
                </Column>
                <Column
                  align="center"
                  mt="5%"
                  width="100%"
  
                >
                  <TextWrapper
                    color="white"
                    size="clamp(1rem,3vw,1.3rem)"
                    margin="0 0 1rem"
                    lineHeight="1.1rem"
                    maxWidth="480px"
                    align="center"
                  >
                    Download financial plan
                  </TextWrapper>
                  <ButtonContainer style={{ border: "1px dotted #DC1C13", padding: "10px", maxWidth: "350px" }}>
                    <Button fontBig primary>
                      <a
                        href={
                          "Lokalai_financing_v3_updarePriceandMarketing.xlsx"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "#DC1C13" }}
                      >
                        Excel
                      </a>
                    </Button>

                    <Button fontBig primary>
                      <a
                        href={
                          "Lokalai_financing_v3_updarePriceandMarketing.xlsx"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "WHITE" }}
                      >
                        PDF
                      </a>
                    </Button>
                  </ButtonContainer>
                </Column>
              </>
            )}
          </FormColumn>
        </FormRow>
      </Container>
    </FormSection>
  );
};

export default Business;
