import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Column,
  MainHeading,
  Row,
  TextWrapper,
} from "../../globalStyles";
// import { BiPlayCircle } from 'react-icons/bi';
import { HeroSection, ButtonContainer } from "./HeroStyles";
import "./buttonbusiness.css";
import "./textbg.css";

const Hero = () => {
  return (
    <HeroSection id="hero">
      <Row justify="center" align="center" height="100%" padding="2rem">
        <Column align="center" mb="10%">
          <div class="text-container">
            <MainHeading>Lokalai Owner Page</MainHeading>
          </div>
          <TextWrapper
            color="white"
            size="clamp(1rem,3vw,1.3rem)"
            margin="0 0 2rem"
            lineHeight="1.1rem"
            maxWidth="480px"
            align="center"
          >
            Lokalai is an innovative IT software service company that aims to
            revolutionise the way retail shops and their customers interact.
          </TextWrapper>
          <ButtonContainer>
            <Link to={"/business"}>
              <div className="heroneon">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Business Plan
              </div>

              {/* <Button big fontBig>
								Business Plan
							</Button> */}
            </Link>
            {/* <Button big fontBig> */}
            {/* <BiPlayCircle size="2.3rem" /> */}
            {/* More Info
						</Button> */}
          </ButtonContainer>
        </Column>
      </Row>
    </HeroSection>
  );
};

export default Hero;
